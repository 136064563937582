import PreviewController from "../../campaign/preview_controller"
import {Piece} from "@/controllers/compose/models/piece";

export default class extends PreviewController {
  static values = {
    stepId: String,
  }

  initialize() {
    super.initialize()
    this.observer = new ResizeObserver(() => {
      this.dispatch("size:change", { target: document.documentElement })
    })

    this.content = this.contentValue
  }

  connect() {
    this.observer.observe(this.element)
    super.connect()
  }

  sync({ detail }) {
    this.updatePreview({ detail })
  }

  updatePreview({ detail }) {
    this.paragraphsTarget.innerHTML = ''

    if(!detail.string) {
      this.paragraphsTarget.innerHTML = this.translations.campaign.broadcasts.new.fields.body.placeholder
      return
    }

    super.updatePreview({ detail })
  }

  saveChanges({ detail }) {
    if (detail.stepId === this.stepIdValue && detail.commit) {
      this.smsCount = detail.smsCount
      this.content = detail.body
      this.smsLength = detail.smsLength || 160
      this.sync({ detail })
    }
  }

  abortChanges({ detail }) {
    if (detail.stepId === this.stepIdValue && this.content) {
      super.sync({ data: { smsCount: this.smsCount, body: this.content, smsLength: this.smsLength || 160 } })
    }
  }
}
