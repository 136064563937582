import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    active: Boolean
  }

  static targets = [
    'list',
    'item',
    'template',
    'orValueButton',
  ]

  connect() {
    super.connect()

    if(!this.activeValue) {
      this.element.querySelectorAll('input, select').forEach(this.disable)
    }
  }

  onFilterItemChange({ target }) {
    const parent = target.closest('[data-filter--group-target="item"]')
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    if(parent === lastItem) {
      if(target.value) {
        this.show(this.orValueButtonTarget)
      } else {
        this.hide(this.orValueButtonTarget)
      }
    }
  }

  onInputEnter({ target }) {
    if(!target.value) return

    const parent = target.closest('[data-filter--group-target="item"]')
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    if(parent === lastItem) {
      this.addNewItem()
    }
  }

  addNewItem() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const newItem = this.templateTarget.cloneNode(true)

    newItem.setAttribute('data-filter--group-target', 'item')
    newItem.setAttribute('data-controller', 'filter--group-item')
    newItem.classList.remove('hidden')
    newItem.querySelectorAll('input, select').forEach(this.enable)

    this.listTarget.insertBefore(newItem, this.templateTarget)

    this.nextTick(() => {
      this.focus(newItem.querySelector('input'))
      this.hide(this.orValueButtonTarget)
    })

    lastItem.querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.remove('hidden')
  }

  removeItem({ currentTarget }) {
    const item = currentTarget.closest('[data-filter--group-target="item"]')
    item.remove()
  }

  itemTargetDisconnected() {
    const lastItem = this.itemTargets[0]

    const input = lastItem.querySelector('input')
    this.focus(input, { moveCursorToEnd: true })

    if(input.value) {
      this.show(this.orValueButtonTarget)
    }

    if(this.itemTargets.length > 1) return

    this.hide(lastItem.querySelector('[data-filter--group-item-target="removeButtonContainer"]'))
    this.hide(lastItem.querySelector('[data-filter--group-item-target="orLabel"]'))
  }

  showAndEnable() {
    this.show()

    this.itemTargets.forEach((item) => {
      item.querySelectorAll('input, select').forEach(this.enable)
      this.focusFirstItem()
    })

    this.focusFirstItem()
  }

  hideAndDisable() {
    this.hide()
    this.element.querySelectorAll('input, select').forEach(this.disable)
  }

  focusFirstItem() {
    this.nextTick(() => {
      this.focus(this.itemTargets[0].querySelector('input'))
    })
  }

  saveState() {
    this.itemTargets.forEach((item) => {
      this.dispatch('state:save', {
        target: item
      })
    })
  }

  restoreState() {
    this.itemTargets.forEach((item) => {
      this.dispatch('state:restore', {
        target: item
      })
    })
  }
}
