import ApplicationController from '../application_controller'
import {
  Cursor,
  Parser
} from "./models"

export default class extends ApplicationController {
  static values = {
    autoFocus: { type: Boolean, default: true }
  }

  static targets = [
    'input',
    'trix',
    'reset'
  ]

  initialize() {
    super.initialize()

    this.respondToTrixEvent = this.respondToTrixEvent.bind(this)
    this.onTrixSelectionChange = this.onTrixSelectionChange.bind(this)

    this.cursor = new Cursor(this)
    this.parser = new Parser(this)
  }

  connect() {
    super.connect()
    this.registerTrixEventListeners()
  }

  disconnect() {
    this.unregisterTrixEventListeners()
    super.disconnect()
  }

  onTrixInitialize() {
    this.initialized = true
    this.disableDefaultTrixToolbar()
  }

  onTrixSelectionChange() {
    this.oldPosition = this.trixPosition
    this.trixPosition = this.editor.getSelectedRange()
  }

  disableDefaultTrixToolbar() {
    this.element.querySelector('trix-toolbar').remove()
  }

  ensureWhitespaceBeforeCursor() {
    const characterAtCursor = this.cursor.characterAtCaret()
    const [_, end] = this.editor.getSelectedRange()

    if (characterAtCursor.isWhitespace) {
      this.editor.setSelectedRange(end + 1)
    } else {
      this.editor.setSelectedRange(end)

      this.insertWhitespace()
      this.cursor.moveToRight()
    }
  }

  ensureWhitespaceAfterCursor() {
    const [start, _] = this.editor.getSelectedRange()

    this.editor.setSelectedRange(start)

    this.insertWhitespace()
    this.cursor.moveToLeft()
  }

  contentLengthBeforePiece(index) {
    return this.trixDocument
      .getPieces()
      .filter((piece, position) => position < index)
      .map(piece => piece.attachment ? 1 : piece.string.length)
      .reduce((a, b) => a + b, 0)
  }

  resetContent() {
    this.editor.setSelectedRange([0, this.editor.getDocument().toString().length])
    this.editor.deleteInDirection('backward')
  }

  deleteStartingZeroWidthNonJoinerSpace() {
    this.editor.setSelectedRange([0, 0])
    this.editor.deleteInDirection("forward")

    this.nextTick(() => {
      const [start, end] = this.editor.getSelectedRange()
      this.editor.setSelectedRange([start + 1, end + 1])
    })
  }

  enable() {
    if(!this.initialized) return

    this.inputContainerTarget.removeAttribute('disabled')
    this.trixTarget.editor.element.setAttribute('contentEditable', true)

    this.trixTarget.setAttribute('placeholder', this.translations.compose.placeholder.active)
  }

  disable() {
    if(!this.initialized) return

    this.inputContainerTarget.setAttribute('disabled', true)
    this.trixTarget.editor.element.setAttribute('contentEditable', false)

    this.trixTarget.value = ''
    this.trixTarget.setAttribute('placeholder', this.translations.compose.placeholder.disabled)
  }

  get isEnabled() {
    return !this.inputContainerTarget.hasAttribute('disabled')
  }

  get isDisabled() {
    return !this.isEnabled
  }

  // private

  insertWhitespace() {
    this.editor.recordUndoEntry('insert whitespace')
    this.editor.insertString(' ')
  }

  registerTrixEventListeners() {
    this.methods = {
      'trix-change': 'onTrixContentChange',
      'trix-initialize': 'onTrixInitialize',
      'keydown': 'onTrixKeydown',
      'keyup': 'onTrixKeyup',
      'blur': 'onTrixBlur',
      'focus': 'onTrixFocus',
      'trix-selection-change': 'onTrixSelectionChange',
      'trix-paste': 'onTrixPaste',
      'click': 'onTrixClick',
    }

    Object.entries(this.methods)
      .filter(this.respondToTrixEvent)
      .forEach(([eventName, methodName]) => {
        this.trixTarget.addEventListener(eventName, this[methodName].bind(this))
      })
  }

  unregisterTrixEventListeners() {
    Object.entries(this.methods)
      .filter(this.respondToTrixEvent)
      .forEach(([eventName, methodName]) => {
        this.trixTarget.removeEventListener(eventName, this[methodName].bind(this))
      })
  }

  respondToTrixEvent([_, methodName]) {
    return this[methodName]
  }

  restoreSelectionTo([start, end]) {
    this.restoringSelection = true
    this.nextTick(() => this.restoringSelection = false)

    this.editor.setSelectedRange([start, end])
  }

  get noSelection() {
    const [start, end] = this.editor.getSelectedRange()
    return start === end
  }

  get editor() {
    return this.trixTarget.editor
  }

  get trixDocument() {
    return this.editor.getDocument()
  }

  get documentStringWithoutZeroWidthNonJoinerAndLineFeed() {
    return this.trixDocument.toString().replace(/\u200C/g, '').replace(/\n/g, '')
  }

  get documentStartsWithZeroWidthNonJoinerSpace() {
    const documentString = this.trixDocument.toString()
    return (documentString.length > 2 && documentString.charCodeAt(0) === 8204)
  }

  get currentRange() {
    return this.editor.getSelectedRange()
  }

  get inTemplateCreationMode() {
    return this.mode === 'template'
  }

  get mode() {
    return this.element.dataset.mode
  }
}
