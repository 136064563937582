import { get } from '@rails/request.js'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    turboControl: { type: Boolean, default: true },
    search: { type: String, default: '' },
    url: String,
  }

  static targets = [
    'input',
    'url',
    'skeletonList',
    'content',
    'clearButton',
    'sortOption',
  ]

  initialize() {
    super.initialize()

    this.search = this.search.bind(this)
    this.popStateListener = this.popStateListener.bind(this)

    this.urlValue ||= window.location.href
  }

  connect() {
    if(this.turboControlValue) {
      window.addEventListener('popstate', this.popStateListener)
      this.nextTick(this.popStateListener)
    }

    super.connect()
  }

  disconnect() {
    if(this.turboControlValue) {
      window.removeEventListener('popstate', this.popStateListener)
    }

    super.disconnect()
  }

  popStateListener() {
    const url = new URL(this.urlValue)
    const search = url.searchParams.get('search') || ''

    if(search !== this.searchValue) {
      this.inputTarget.value = search
      this.searchValue = search

      this.search({ action: 'replace' })
    }

    if(this.didSearch && !search) {
      this.clear()
    }

    const selected = new URLSearchParams(window.location.search).get('order') || 'activity'

    this.sortOptionTargets.forEach(option => {
      if(option.dataset.value === selected) {
        this.addClass(option, 'bg-lavender-light')
      } else {
        this.removeClass(option, 'bg-lavender-light')
      }
    })
  }

  scheduleTimeout() {
    if(this.inputTarget.value) {
      this.show(this.clearButtonTarget)
      this.debounce(this.search)
    } else {
      clearTimeout(this.debounceTimeout)

      this.search()
      this.clear()
    }
  }

  search({ action } = {}) {
    if(!this.hasContentTarget) return

    this.show(this.skeletonListTarget)
    this.hide(this.contentTarget)

    this.searchValue = this.inputTarget.value

    const url = new URL(this.urlValue)

    url.searchParams.delete('viewed')
    url.searchParams.delete('search')

    if(this.inputTarget.value) {
      url.searchParams.append('search', this.inputTarget.value)
    }

    if(this.turboControlValue) {
      Turbo.visit(url.toString(), { frame: 'main-content', action: action || 'advance' })
    } else {
      get(url.toString(), { responseKind: 'turbo-stream' })
    }
  }

  clear() {
    this.inputTarget.value = ''

    this.hide(this.clearButtonTarget)
    this.hide(this.skeletonListTarget)

    this.show(this.contentTarget)

    this.searchValue = ''
    this.search({ action: 'replace' })
  }

  reset() {
    this.inputTarget.value = ''
    this.searchValue = ''

    this.sortOptionTargets.forEach(option => {
      if(option.dataset.value === 'activity') {
        this.addClass(option, 'bg-lavender-light')
      } else {
        this.removeClass(option, 'bg-lavender-light')
      }
    })
  }

  sort({ currentTarget }) {
    const { value } = currentTarget.dataset
    this.sortValue = value

    this.sortOptionTargets.forEach((target) => {
      if(target === currentTarget) {
        this.addClass(target, 'bg-lavender-light')
      } else {
        this.removeClass(target, 'bg-lavender-light')
      }
    })

    currentTarget.blur()

    const url = new URL(this.urlValue)
    url.searchParams.set('order', value)

    Turbo.visit(url.toString(), { frame: 'main-content', action: 'advance' })
  }

  // private

  searchValueChanged() {
    if(this.searchValue) {
      this.show(this.clearButtonTarget)
    } else {
      this.hide(this.clearButtonTarget)
    }
  }

  get didSearch() {
    return this.searchValue !== ''
  }
}
