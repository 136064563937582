import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    inputName: String
  }

  static targets = ['buttonsContainer', 'buttonGroupTemplate', 'buttonTemplate', 'group']

  initialize() {
    super.initialize()
    this.types = {}
  }

  connect() {
    super.connect()

    this.buildInputGroupTypes()
    this.insertInputs()

    if(Object.keys(this.types).length > 0) {
      this.show(this.buttonsContainerTarget)
    } else {
      this.hide(this.buttonsContainerTarget)
    }
  }

  insertButtons({ detail: { buttons, type, element } }) {
    if(this.groupTargets.find(group => group.dataset.type === type)) {
      this.insertToCurrentGroup({ buttons, type, element })
    } else {
      this.insertNewGroup({ buttons, type, element })
    }

    if(this.isPreviewable) {
      this.previewButtons()
    }

    this.show(this.buttonsContainerTarget)
  }

  insertNewGroup({ buttons, type, element }) {
    this.types[type] = {
      buttons,
      nodes: []
    }

    const buttonGroupElement = this.buttonGroupTemplateTarget.cloneNode(true)
    buttonGroupElement.setAttribute('data-compose--template-target', 'group')
    buttonGroupElement.setAttribute('data-type', type)

    const contentElement = buttonGroupElement.querySelector('[data-content]')
    buttons.forEach((button, index) => {
      const buttonElement = this.buttonTemplateTarget.cloneNode(true)
      buttonElement.innerText = button.text
      buttonElement.setAttribute('data-position', index)

      this.show(buttonElement)
      contentElement.appendChild(buttonElement)
    })

    const dropdown = buttonGroupElement.querySelector(`[data-controller*="compose--template--button-list"][data-type="${type}"]`)

    Array.from(element.children).forEach(button => {
      if(button.getAttribute('data-compose--template--button-list-target') === 'template' || !button.hasAttribute('data-valid')) return
      dropdown.appendChild(button.cloneNode(true))

      this.types[type].nodes = [...this.types[type].nodes, button.cloneNode(true)]
    })

    this.show(dropdown)
    this.show(buttonGroupElement)

    if(type !== 'quick_reply' && Object.keys(this.types).find(type => type === 'quick_reply')) {
      const quickReplyGroup = this.groupTargets.find(group => group.dataset.type === 'quick_reply')

      if(quickReplyGroup.nextElementSibling) {
        this.buttonsContainerTarget.appendChild(buttonGroupElement)
      } else if(quickReplyGroup.previousElementSibling) {
        this.buttonsContainerTarget.insertBefore(buttonGroupElement, quickReplyGroup)
      } else {
        this.buttonsContainerTarget.appendChild(buttonGroupElement)
      }
    } else {
      this.buttonsContainerTarget.appendChild(buttonGroupElement)
    }

    this.groupTargets.forEach((group, index) => {
      this.types[group.dataset.type].index = index
    })

    this.insertInputs()
  }

  loadTemplateButtons({ detail }) {
    this.buttonsContainerTarget.innerHTML = ''
    const section = detail.element.querySelector('[data-buttons]')?.cloneNode(true)

    if(section) {
      section.querySelectorAll('input').forEach(this.enable)

      Array.from(section.children).forEach(group=> {
        group.setAttribute('data-compose--template-target', 'group')
        this.buttonsContainerTarget.appendChild(group)
      })
      section.remove()

      this.show(this.buttonsContainerTarget)
      this.buildInputGroupTypes()
    } else {
      this.types = {}
      this.hide(this.buttonsContainerTarget)
    }

    this.insertInputs()
    this.previewButtons()
  }

  buildInputGroupTypes() {
    this.groupTargets.forEach((group, index) => {
      const buttons = JSON.parse(group.dataset.json)
      const nodes = Array
        .from(group.querySelectorAll('[data-compose--template--button-list-target="item"]'))
        .map(node => node.cloneNode(true))

      this.types[group.dataset.type] = {
        index,
        buttons,
        nodes,
      }
    })
  }

  removeGroup({ target }) {
    const type = target.closest('[data-compose--template-target="group"]').dataset.type
    target.closest('[data-compose--template-target="group"]').remove()

    delete this.types[type]
    this.insertInputs()

    if(Object.keys(this.types).length === 0) {
      this.hide(this.buttonsContainerTarget)
    }

    if(this.isPreviewable) {
      this.previewButtons()
    }
  }

  insertToCurrentGroup({ buttons, type, element }) {
    const groupElement = this.groupTargets.find(group => group.dataset.type === type)
    const contentElement = groupElement.querySelector('[data-content]')
    const buttonList = groupElement.querySelector('[data-controller*="compose--template--button-list"]')

    this.types[type] = {
      buttons: [...this.types[type].buttons, ...buttons],
      nodes: [...this.types[type].nodes, ...Array.from(element.children)].map(node => node.cloneNode(true))
    }

    buttons.forEach(button => {
      const buttonElement = this.buttonTemplateTarget.cloneNode(true)
      buttonElement.innerText = button.text

      this.show(buttonElement)
      contentElement.appendChild(buttonElement)
    })

    Array.from(element.children).forEach(button => {
      if(button.getAttribute('data-compose--template--button-list-target') === 'template' || !button.hasAttribute('data-valid')) return

      buttonList.appendChild(button.cloneNode(true))
    })

    this.insertInputs()
  }

  saveChanges({ target }) {
    const group = target.closest('[data-compose--template-target="group"]')
    const buttonList = group.querySelector('[data-controller*="compose--template--button-list"]')
    const controller = this.application.getControllerForElementAndIdentifier(buttonList, 'compose--template--button-list')
    const object = controller.toObject()

    const contentElement = group.querySelector('[data-content]')
    contentElement.innerHTML = ''

    object.buttons.forEach(button => {
      const buttonElement = this.buttonTemplateTarget.cloneNode(true)
      buttonElement.innerText = button.text

      this.show(buttonElement)
      contentElement.appendChild(buttonElement)
    })

    this.types[group.dataset.type] = {
      buttons: object.buttons,
      nodes: Array.from(buttonList.children).map(node => node.cloneNode(true)),
    }

    if(this.isPreviewable) {
      this.dispatch('buttons:preview', {
        target: document.querySelector('[data-controller="template--preview"]'),
        detail: this.types
      })
    }

    this.insertInputs()
  }

  reset({ target }) {
    const group = target.closest('[data-compose--template-target="group"]')
    const type = group.dataset.type
    const buttonList = group.querySelector(`[data-controller*="compose--template--button-list"][data-type="${type}"]`)
    const contentElement = group.querySelector('[data-content]')

    buttonList.innerHTML = ''
    contentElement.innerHTML = ''

    this.types[group.dataset.type].buttons.forEach((button, index) => {
      const buttonElement = this.buttonTemplateTarget.cloneNode(true)
      buttonElement.innerText = button.text
      buttonElement.setAttribute('data-position', index)

      this.show(buttonElement)
      contentElement.appendChild(buttonElement)
    })

    Array.from(this.types[type].nodes).forEach(button => {
      buttonList.appendChild(button.cloneNode(true))
    })
  }

  focusGroup({ target, currentTarget }) {
    const group = target.closest('[data-compose--template-target="group"]')
    const list = group.querySelector(`[data-controller*="compose--template--button-list"][data-type="${group.dataset.type}"]`)

    this.dispatch('focus', {
      target: list,
      detail: {
        position: parseInt(currentTarget.dataset.position)
      }
    })
  }

  abortEditingGroup() {
    if(this.groupTargets.every(group => this.isInvisible(group.querySelector('[data-dropdown-target="menu"]')))) return

    const visibleGroup = this.groupTargets
      .find(group => this.isVisible(group.querySelector('[data-dropdown-target="menu"]')))

    const cancelButton = visibleGroup.querySelector('[data-type="cancel"]')

    cancelButton.click()
  }

  displayBodyPreview({ target, detail }) {
    this.dispatch('body:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: target.value
    })
  }

  displayAttachmentPreview({ detail: attachmentElement }) {
    if(!this.isPreviewable) return

    this.dispatch('attachment:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: attachmentElement
    })
  }

  hideAttachmentPreview() {
    if(!this.isPreviewable) return

    this.dispatch('attachment:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: 0
    })
  }

  displayBodyPreview({ detail }) {
    if(!this.isPreviewable) return

    this.dispatch('body:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail
    })
  }

  displayFooterPreview({ target }) {
    if(!this.isPreviewable) return

    this.dispatch('footer:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: target.value
    })
  }

  previewButtons() {
    this.dispatch('buttons:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: this.types
    })
  }

  resetGroupState({ detail, srcElement }) {
    this.reset({ target: srcElement })
  }

  insertInputs() {
    this.element.querySelector('[data-inputs-container]').innerHTML = ''

    const sortedButtons =   Object.keys(this.types)
      .sort((a, b) => this.types[a].index - this.types[b].index)
      .map(type => this.types[type].buttons)
      .flat()

    sortedButtons.forEach((button, index) => {
      const type = button.type
      const methodName = type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')

      this[`insert${methodName}Inputs`](button, index)
    })
  }

  insertQuickReplyInputs(button, index) {
    ['title', 'type'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = button[attribute]

      this.insertInput(name, value)
    })
  }

  insertCopyCodeInputs(button, index) {
    ['text', 'type'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = button[attribute]

      this.insertInput(name, value)
    })
  }

  insertUrlInputs(button, index) {
    ['title', 'url', 'type'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = button[attribute]

      this.insertInput(name, value)
    })
  }

  insertPhoneInputs(button, index) {
    ['title', 'number', 'type'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = button[attribute]

      this.insertInput(name, value)
    })
  }

  insertInput(name, value) {
    const input = document.createElement('input')

    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)

    input.value = value

    this.element.querySelector('[data-inputs-container]').appendChild(input)
  }

  get isPreviewable() {
    return document.querySelector('[data-controller="template--preview"]')
  }
}
