import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['trigger']

  openMap() {
    this.dispatch("show", {target: this.mapContainer})
  }

  enable() {
    super.enable(this.triggerTarget)
  }

  disable() {
    super.disable(this.triggerTarget)
  }

  reset() {
    this.dispatch("hide", { target: this.mapContainer })
  }

  get mapContainer() {
    return document.querySelector("map-container")
  }
}
