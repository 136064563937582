import { computePosition, autoUpdate, shift } from '@floating-ui/dom'

import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['menu', 'radio', 'list', 'cancelButton', 'triggerButton']

  initialize() {
    super.initialize()
    this.resetList = this.resetList.bind(this)
  }

  connect() {
    super.connect()

    this.floatingUICleanup = autoUpdate(this.triggerButtonTarget, this.menuTarget, () => {
      computePosition(this.triggerButtonTarget, this.menuTarget, {
        placement: 'bottom-start',
        middleware: [
          shift({ padding: 24 })
        ]
      }).then(({x, y}) => {
        Object.assign(this.menuTarget.style, {
          left: `${x}px`,
        })
      })
    })
  }

  disconnect() {
    super.disconnect()
    this.floatingUICleanup()
  }

  insert() {
    const controller = this.application.getControllerForElementAndIdentifier(this.activeItem, `compose--template--button-list`)
    const object = controller.toObject()

    if(object.buttons.length > 0) {
      this.dispatch('insert', {
        detail: {
          ...object,
          element: this.activeItem.cloneNode(true)
        }
      })
    }

    this.cancel()
  }

  insertByEnter(e) {
    if(this.isInvisible(this.menuTarget)) return

    e.preventDefault()
    e.stopPropagation()

    this.insert()
  }

  cancel() {
    this.removeActiveClassOnTriggerButton()
    this.element.setAttribute('data-dropdown-open-value', false)

    this.dispatch('canceled')

    this.listTargets.forEach(this.resetList)
    this.radioTargets.find(radio => radio.dataset.type === 'quick_reply').checked = true
  }

  closeByEscape() {
    if(this.isInvisible(this.menuTarget)) return

    this.cancelButtonTarget.click()
    this.radioTargets.find(radio => radio.value === 'quick_reply').checked = true
  }

  focus({ currentTarget }) {
    this.addClass(this.triggerButtonTarget, 'icon-button--compose--tiger')
    const type = currentTarget.dataset.type || 'quick_reply'

    this.listTargets.forEach(list => {
      if(list.dataset.type === type) {
        this.show(list)
        this.dispatch('focus', {
          target: list
        })
      } else {
        this.hide(list)
        this.resetList(list)
      }
    })
  }

  resetList(list) {
    this.dispatch('reset', {
      target: list
    })
  }

  removeActiveClassOnTriggerButton() {
    this.removeClass(this.triggerButtonTarget, 'icon-button--compose--tiger')
  }

  get activeItem() {
    return this.listTargets.find(list => this.isVisible(list))
  }
}
