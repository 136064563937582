import { computePosition, autoUpdate, shift } from "@floating-ui/dom"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    placement: { type: String, default: "bottom-start" },
    shiftPadding: { type: Number, default: 24 },
    open: { type: Boolean, default: false }
  }

  static targets = ['trigger', 'popover']

  connect() {
    const milddlewares = [
      shift({ padding: this.shiftPaddingValue })
    ]

    this.floatingUICleanup = autoUpdate(this.triggerTarget, this.popoverTarget, () => {
      computePosition(this.triggerTarget, this.popoverTarget, {
        placement: this.placementValue,
        middleware: milddlewares,
      }).then(({x, y}) => {
        Object.assign(this.popoverTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    })
  }

  show() {
    this.openValue = true
  }

  toggle() {
    this.openValue = !this.openValue
  }

  onClickOutside(event) {
    if(event.target.closest('dialog') || event.target.tagName === 'DIALOG') return

    if (event.target.nodeType && this.element.contains(event.target) === false) {
      this.openValue = false
      setTimeout(() => this.dispatch("aborted"), 400)
    }
  }

  openValueChanged() {
    this.dispatch("toggle", {
      detail: this.openValue
    })

    if(this.openValue) {
      this.popoverTarget.showPopover()
      this.popoverTarget.setAttribute("aria-expanded", "true")

      this.dispatch("opened")
    } else {
      this.popoverTarget.hidePopover()
      this.popoverTarget.removeAttribute("aria-expanded")

      this.dispatch("hidden")
    }
  }
}
