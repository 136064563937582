import { get } from "@rails/request.js"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    load: Boolean,
    target: String,
    count: Object
  }

  static targets = [
    'contactsCountParagraph',
  ]

  getTargetAudienceForTechnology({ currentTarget }) {
    this.targetValue = currentTarget.dataset.technology
  }

  // private

  targetValueChanged() {
    this.contactsCountParagraphTarget.innerText =
      this.translations.campaigns.wizard.technologies.wizard.aside.subscribers_count.replace('%{count}', this.count)
  }

  get count() {
    if(this.targetValue !== 'all') {
      return this.countValue[this.targetValue]
    }

    return Object.values(this.countValue).reduce((total, count) => total + count, 0)
  }
}
