import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onClick(e) {
    if (this.onMobile && window.location.href.includes("inbox/")) {
      e.stopPropagation()
      e.preventDefault()

      let event = new CustomEvent("inbox:toggle", {
        bubbles: true,
        cancelable: true,
      })
      event.data = this.element.dataset

      window.dispatchEvent(event)
    }
  }

  get onMobile() {
    return screen.availWidth < 600
  }
}
