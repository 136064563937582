import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["all", "technology", "error"]
  static classes = ["enabled", "disabled"]

  initialize() {
    this.checkAndDisableTechnology = this.checkAndDisableTechnology.bind(this)
    this.checkAndEnableTechnology = this.checkAndEnableTechnology.bind(this)
  }

  connect() {
    if(this.allTarget.checked) {
      this.messageLengthChangedToLowest()
    }

    if(this.checkedTechnologies.length === 1) {
      this.changeMessageLengthToCheckedTechnology(this.checkedTechnologies[0])
    }
  }

  onSendViaAllClick() {
    if(this.allTarget.checked) {
      this.technologyTargets.forEach(this.checkAndDisableTechnology)
      this.messageLengthChangedToLowest()

      this.dispatch('changed', {
        detail: ['all']
      })

      this.hide(this.errorTarget)
    } else {
      this.technologyTargets.forEach(this.checkAndEnableTechnology)
    }
  }

  onTechnologyClick() {
    if(this.checkedTechnologies.length === 1) {
      this.changeMessageLengthToCheckedTechnology(this.checkedTechnologies[0])
    } else {
      this.messageLengthChangedToLowest()
    }

    if(this.checkedTechnologies.length === 0) {
      this.show(this.errorTarget)
    } else {
      this.hide(this.errorTarget)
    }

    this.dispatch('changed', {
      detail: this.checkedTechnologies.map(technology => technology.dataset.hashedId)
    })
  }

  restoreSnapshot({ technologies }) {
    if(technologies.includes('all')) {
      this.allTarget.checked = true
      this.technologyTargets.forEach(this.checkAndDisableTechnology)
    } else {
      this.allTarget.checked = false

      this.technologyTargets.forEach(technology => {
        technology.checked = technologies.includes(technology.dataset.hashedId)
        technology.disabled = false

        this.enableTechnology(technology)
      })
    }
  }

  // private

  changeMessageLengthToCheckedTechnology(technology) {
    this.dispatch("messageLengthChange", {
      detail: {
        name: technology.dataset.name,
        maxMessageLength: Number.parseInt(technology.dataset.maxMessageLength)
      }
    })
  }

  messageLengthChangedToLowest() {
    if(!this.technologyWithLowestMessageLength) return

    this.dispatch("messageLengthChange", {
      detail: this.technologyWithLowestMessageLength
    })
  }

  checkAndDisableTechnology(technologyCheckbox) {
    technologyCheckbox.disabled = technologyCheckbox.checked = true
    technologyCheckbox.parentElement.classList.add(...this.disabledClasses)
    technologyCheckbox.parentElement.classList.remove(...this.enabledClasses)
  }

  checkAndEnableTechnology(technologyCheckbox) {
    technologyCheckbox.disabled = false
    this.enableTechnology(technologyCheckbox)
  }

  enableTechnology(technologyCheckbox) {
    technologyCheckbox.parentElement.classList.remove(...this.disabledClasses)
    technologyCheckbox.parentElement.classList.add(...this.enabledClasses)
  }

  get technologyWithLowestMessageLength() {
    const lowestMessageLength = Math.min(
      ...this.checkedTechnologies.map(checkbox => Number.parseInt(checkbox.dataset.maxMessageLength))
    )

    const technology = this.checkedTechnologies.find(
      technology => technology.dataset.maxMessageLength === lowestMessageLength.toString()
    )


    return technology ? { name: technology.dataset.name, maxMessageLength: lowestMessageLength } : null
  }

  get checkedTechnologies() {
    return this.technologyTargets.filter(technology => technology.checked)
  }

  get snapshot() {
    return {
      technologies: this.allTarget.checked ? ['all'] : this.checkedTechnologies.map(technology => technology.dataset.hashedId),
      maxMessageLength: this.technologyWithLowestMessageLength?.maxMessageLength
    }
  }
}
