import {Controller} from "@hotwired/stimulus"
import {DirectUpload} from "@rails/activestorage"

import {useToastNotifiers} from "../mixins/useToastNotifiers"

class DirectUploadStatusCode {
  constructor(responseString) {
    this.statusCode = Number.parseInt(responseString.split("Status: ")[1])
  }

  get forbidden() {
    return this.statusCode === 403
  }
}

export default class extends Controller {
  static values = {
    expiredSubscription: String,
    inputName: String,
    upload: { type: Boolean, default: true }
  }

  static targets = ["input", "overlay", "removeButton", "overlayRemoveButton", "error", "retryButton", "overlayActions"]

  initialize() {
    if(this.uploadValue) {
      this.upload = new DirectUpload(this.inputTarget.files[0], this.inputTarget.dataset.directUploadUrl, this)
    }
  }

  connect() {
    useToastNotifiers(this)

    if(this.uploadValue) {
      this.startUpload()
    }
  }

  startUpload() {
    this.element.setAttribute("data-uploading", "")

    this.upload.create((error, blob) => {
      if(error) {
        this.onUploadError(error)
      } else {
        this.element.removeAttribute("data-uploading")
        this.element.removeAttribute("data-upload-error")

        this.element.setAttribute("data-uploaded", "")

        let hiddenField = document.createElement('input')
        hiddenField.type = "hidden"
        hiddenField.value = blob.signed_id;
        hiddenField.name = `${this.inputNameValue}[signed_id]`

        this.element.appendChild(hiddenField)

        hiddenField = document.createElement('input')
        hiddenField.type = "hidden"
        hiddenField.value = blob.key;
        hiddenField.name = `${this.inputNameValue}[id]`

        this.element.appendChild(hiddenField)
      }
    })
  }

  retryUpload() {
    this.displayInitialState()
    this.startUpload()
  }

  remove() {
    this.dispatch('removed')
    this.element.remove()
  }

  // private

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      "progress",
      event => this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    if(event.loaded === event.total) {
      this.overlayTarget.classList.add("hidden")
      this.removeButtonTarget.classList.add("group-hover:flex")
    }
  }

  onUploadError(error) {
    const statusCode = new DirectUploadStatusCode(error)

    if(statusCode.forbidden) {
      this.showToastErrorMessage(this.expiredSubscriptionValue)
    }

    this.overlayActionsTarget.classList.remove("hidden")

    this.element.removeAttribute("data-uploading")
    this.element.setAttribute("data-upload-error", "")

    this.showOverlay()

    this.removeButtonTarget.classList.remove("group-hover:flex")
    this.overlayRemoveButtonTarget.classList.add("group-hover:flex")

    this.retryButtonTarget.classList.add("hidden", "group-hover:flex")
    this.errorTarget.classList.remove("hidden")
  }

  showOverlay() {
    this.overlayTarget.classList.remove("hidden")
    this.overlayTarget.classList.add("hover:bg-blush-80")
    this.overlayTarget.querySelector("[data-spinner]").classList.add("hidden")
  }

  displayInitialState() {
    this.overlayTarget.classList.remove("hidden")

    this.overlayActionsTarget.classList.add("hidden")
    this.overlayTarget.querySelector("[data-spinner]").classList.remove("hidden")

    this.retryButtonTarget.classList.add("hidden")
    this.retryButtonTarget.classList.remove("group-hover:flex")

    this.overlayRemoveButtonTarget.classList.remove("group-hover:flex")

    this.removeButtonTarget.classList.remove("group-hover:flex")
    this.errorTarget.classList.add("hidden")
  }

  get forbidden() {
    return 403
  }

  get inputName() {
    return this.inputNameValue || "attachments[]"
  }
}
