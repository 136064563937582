import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    limit: { type: Number, default: 60 }
  }

  static targets = ['input']

  sync(e) {
    const { target } = e

    if(target.value.length > this.limitValue) {
      target.value = target.value.substring(0, this.limitValue)
    }
  }

  update({ detail: footer }) {
    if(!footer) return

    this.inputTarget.value = footer.value || ''
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }

  preventEnter(e) {
    e.preventDefault()
    e.stopPropagation()
  }
}
