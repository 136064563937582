import { Controller } from "@hotwired/stimulus"

import {useMoveCursorToEndOfInput} from "./mixins/useMoveCursorToEndOfInput"

export default class extends Controller {
  static targets = [
    "industriesSelect",
    "customIndustry",
    "customIndustryInput",
    "customIndustryButton",
    "resetIndustrySelectButton"
  ]

  connect() {
    useMoveCursorToEndOfInput(this)
    super.connect()
  }

  showCustomIndustryField() {
    this.industriesSelectTarget.classList.add("hidden")

    this.customIndustryTarget.classList.remove("hidden")
    this.customIndustryInputTarget.disabled = false
    this.moveCursorToEndOfInput(this.customIndustryInputTarget, { focus: true })

    this.resetIndustrySelectButtonTarget.click()
  }

  hideCustomIndustryField() {
    this.industriesSelectTarget.classList.remove("hidden")

    this.customIndustryTarget.classList.add("hidden")
    this.customIndustryInputTarget.disabled = true
    this.customIndustryInputTarget.value = ""
  }
}
