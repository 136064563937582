import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'messageLengthCounterContainer',
    'messageLengthCounter',
    'tool',
    'attachment',
    'recording',
    'recordButton',
    'sendButton'
  ]

  static values = {
    messageLength: { type: Number, default: 160 },
    enabledTools: Array,
    technology: Object
  }

  initialize() {
    super.initialize()
    this.length = 0

    this.enableTool = this.enableTool.bind(this)
    this.disableTool = this.disableTool.bind(this)

    this.toolIsEnabled = this.toolIsEnabled.bind(this)
    this.toolIsDisabled = this.toolIsDisabled.bind(this)

    this.tools = ['attachment', 'location', 'link', 'tag', 'vcard', 'voice', 'emoji']
    this.disabledTools = this.tools.filter(tool => !this.enabledToolsValue.includes(tool))
  }

  updateMessageLengthCounter({ detail }) {
    this.length = detail.string.trim().length
    this.messageLengthValueChanged()
  }

  updateMaxMessageLength({ detail }) {
    const { name, maxMessageLength } = detail

    if(name !== 'sms') {
      return this.hide(this.messageLengthCounterContainerTarget)
    }

    if(this.element.dataset.mode === 'message') {
      this.show(this.messageLengthCounterContainerTarget)
    }

    this.messageLengthValue = maxMessageLength
  }

  onAudioRecordingStarted() {
    this.hide(this.messageLengthCounterContainerTarget)
    this.recordingTargetConnected()
  }

  onAudioRecordingCompleted() {
    this.show(this.messageLengthCounterContainerTarget)
  }

  enableTools({ detail: tools }) {
    tools.filter(this.toolIsDisabled).forEach(this.enableTool)
    this.disabledTools = this.disabledTools.filter(tool => !tools.includes(tool))
  }

  disableTools({ detail: tools }) {
    if(tools === 'all') {
      this.disabledTools = this.toolTargets.map(tool => tool.dataset.kind)
      return this.disabledTools.forEach(this.disableTool)
    }

    tools.forEach(this.disableTool)
    this.disabledTools = Array.from(new Set([...this.disabledTools, ...tools]))
  }

  enableTool(identifier) {
    this.dispatch('enable', {
      target: this.toolTargets.find(tool => tool.dataset.kind === identifier),
    })
  }

  disableTool(identifier) {
    this.dispatch('disable', {
      target: this.toolTargets.find(tool => tool.dataset.kind === identifier),
    })
  }

  toolIsEnabled(identifier) {
    return !this.toolIsDisabled(identifier)
  }

  toolIsDisabled(identifier) {
    return this.disabledTools.includes(identifier)
  }

  attachmentTargetConnected() {
    this.disableTools({ detail: ['vcard', 'voice', 'location'] })
  }

  attachmentTargetDisconnected() {
    if(this.attachmentTargets.length > 0) return

    ['vcard', 'voice', 'location']
      .filter((identifier) => this.enabledToolsValue.includes(identifier))
      .forEach(this.enableTool)
  }

  recordingTargetConnected() {
    this.disableTools({ detail: ['attachment', 'location', 'vcard'] })
  }

  recordingTargetDisconnected() {
    ['attachment', 'location', 'vcard']
      .filter((identifier) => this.enabledToolsValue.includes(identifier))
      .forEach(this.enableTool)

    if(this.element.dataset.mode === 'message') {
      this.show(this.messageLengthCounterContainerTarget)
    }
  }

  onMapVisible() {
    this.disableTools({ detail: ['vcard', 'voice', 'attachment'] })
  }

  onMapHidden() {
    ['vcard', 'voice', 'attachment']
      .filter((identifier) => this.enabledToolsValue.includes(identifier))
      .forEach(this.enableTool)
  }

  reset() {
    this.toolTargets.forEach(tool => {
      this.dispatch('reset', { target: tool })
    })
  }

  syncToolsFromTechnology({ detail }) {
    const { technology, window } = detail
    const { tools, name: identifier } = technology

    this.technologyValue = technology

    if(identifier === 'sms' && this.element.dataset.mode === 'message') {
      this.show(this.messageLengthCounterContainerTarget)
    } else {
      this.hide(this.messageLengthCounterContainerTarget)
    }

    if((!window || !window.open) && identifier !== 'sms') {
      return this.disableTools({ detail: 'all' })
    }

    this.enableTools({ detail: tools })
    const toolsToDisable = this.tools.filter(tool => !tools.includes(tool))

    this.disableTools({ detail: toolsToDisable })
  }

  inheritCouponFromTemplate({ detail: coupon }) {
    this.dispatch('inherit', {
      target: this.toolTargets.find(tool => tool.dataset.kind === 'coupon'),
      detail: coupon,
    })
  }

  showSendButton() {
    super.enable(this.sendButtonTarget)
    this.show(this.sendButtonTarget)

    this.hide(this.recordButtonTarget)
  }

  // private

  technologyValueChanged() {
    if(!this.technologyValue) return

    this.messageLengthValue = this.technologyValue.max_message_length

    this.dispatch('technology:changed', {
      target: this.toolTargets.find(tool => tool.dataset.kind === 'attachment'),
      detail: {
        accept: this.technologyValue.media,
        media: this.technologyValue.media_restrictions,
      }
    })
  }

  messageLengthValueChanged() {
    this.messageLengthCounterTarget.innerText = this.translations.compose.counter.sms
      .replace('%{characters_left}', this.remainingCharactersLength)
      .replace('%{messages_count}', this.messagesCount)

    if(this.technologyValue.name === 'sms') return

    if(this.remainingCharactersLength < 100) {
      this.show(this.messageLengthCounterContainerTarget)
    } else {
      this.hide(this.messageLengthCounterContainerTarget)
    }
  }

  get remainingCharactersLength() {
    return this.messageLengthValue - (this.length % this.messageLengthValue)
  }

  get messagesCount() {
    return Math.ceil(this.length / this.messageLengthValue) > 0 ? Math.ceil(this.length / this.messageLengthValue) : 1
  }
}
