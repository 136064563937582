import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    name: String,
  }

  static targets=["firstName", "lastName", "form", "label"]

  connect() {
    this.dispatch('sync', {
      detail: this.nameValue
    })

    super.connect()
  }

  focus() {
    this.hide(this.labelTarget)
    this.show(this.formTarget)

    super.focus(this.firstNameTarget, { moveCursorToEnd: true })
  }

  submit({ target }) {
    if(this.isInvisible(this.formTarget)|| this.formTarget.contains(target) || this.labelTarget.contains(target)) return
    this.formTarget.requestSubmit()


  }

  restore() {
    this.formTarget.reset()
    this.blur()
  }

  blur() {
    this.hide(this.formTarget)
    this.show(this.labelTarget)
  }
}
