import CampaignPreviewController from '../campaign/preview_controller'
import { Piece } from '../compose/models/piece'

export default class extends CampaignPreviewController {
  static targets = ['header', 'footer', 'buttonContainer', 'buttonTemplate']

  previewAttachment({ detail: attachmentElement }) {
    this.headerTarget.innerHTML = ''

    if(!attachmentElement) {
      return this.hide(this.headerTarget)
    }

    if(attachmentElement.querySelector('img')) {
      const img = attachmentElement.querySelector('img').cloneNode(true)
      this.addClass(img, 'w-full')
      this.headerTarget.appendChild(img)
    } else {
      const content = attachmentElement.querySelector('main').cloneNode(true)
      this.replaceClass(content, 'max-w-[11rem]', 'w-full')
      this.headerTarget.appendChild(content)
    }

    this.show(this.headerTarget)
  }

  previewBody({ detail }) {
    super.updatePreview({ detail })
  }

  previewFooter({ detail: body }) {
    this.footerTarget.innerHTML = body

    if(body) {
      this.show(this.footerTarget)
    } else {
      this.hide(this.footerTarget)
    }
  }

  previewButtons({ detail: groups }) {
    this.buttonContainerTarget.innerHTML = ''

    const buttons = Object.values(groups).map(({ buttons }) => buttons).flat()

    if(buttons.length > 3) {
      this.addClass(this.buttonContainerTarget, 'flex-col', 'space-y-2')
      this.removeClass(this.buttonContainerTarget, 'space-x-1')

      this.replaceClass(this.buttonTemplateTarget, 'w-6/12', 'w-full')
    } else {
      this.removeClass(this.buttonContainerTarget, 'flex-col', 'space-y-2')
      this.addClass(this.buttonContainerTarget, 'space-x-1')

      this.replaceClass(this.buttonTemplateTarget, 'w-full', 'w-6/12')
    }

    buttons.forEach(button => {
      const element = this.buttonTemplateTarget.cloneNode(true)
      element.innerText = button.text
      this.show(element)

      this.buttonContainerTarget.appendChild(element)
    })
  }
}
