import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    disabled: Boolean,
  }

  static targets = [
    'rechargeAmountInput',
  ]

  toggleAutoRecharge(e) {
    Array.from(this.element.elements)
      .filter((el) => el !== e.target && el.nodeName !== "BUTTON")
      .forEach((el) => {
        if (el.dataset.disableable === "true") {
          el.disabled = !this.disabledValue
        }
      })

    this.disabledValue = !this.disabledValue
  }

  disabledValueChanged() {
    if(this.disabledValue) return

    this.focus(this.rechargeAmountInputTarget, { moveCursorToEnd: true })
  }
}
